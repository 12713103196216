import ChatIcon from 'ui-kit/icons/chat';
import CreditCardIcon from 'ui-kit/icons/creditcard-icon/creditcard-icon';
import PharmacistIcon from 'ui-kit/icons/health-benefits/pharmacist';
import PrescriptionIcon from 'ui-kit/icons/health-benefits/prescription';
import MembershipIcon from 'ui-kit/icons/payment-history/birdi-membership';
import CheckCircleIcon from 'ui-kit/icons/payment-history/check-circle';
import CreditCardOutlineIcon from 'ui-kit/icons/payment-history/credit-card-outline';
import ErrorCircleIcon from 'ui-kit/icons/payment-history/error-circle';
import MoneyOutlineIcon from 'ui-kit/icons/payment-history/money-outline';
import PillOutlineIcon from 'ui-kit/icons/payment-history/pill-outline';
import PlusCircleIcon from 'ui-kit/icons/payment-history/plus-circle';
import StethoscopeIcon from 'ui-kit/icons/payment-history/stethoscope';
import VideoCameraIcon from 'ui-kit/icons/video-camera';
import VideoChatIcon from 'ui-kit/icons/video-chat';

import './icon-composer.styles.scss';

export type IconType =
    | 'money'
    | 'membership'
    | 'pill'
    | 'visits'
    | 'video'
    | 'chat'
    | 'video-chat'
    | 'prescription'
    | 'pharmacist';
type SubIconType = 'default' | 'error' | 'refund' | 'positive' | 'check';

const mapIconSrc = (icon: IconType) => {
    switch (icon) {
        case 'money':
            return <MoneyOutlineIcon />;
        case 'membership':
            return <MembershipIcon />;
        case 'pill':
            return <PillOutlineIcon />;
        case 'visits':
            return <StethoscopeIcon />;
        case 'video':
            return <VideoCameraIcon />;
        case 'video-chat':
            return <VideoChatIcon />;
        case 'chat':
            return <ChatIcon />;
        case 'prescription':
            return <PrescriptionIcon />;
        case 'pharmacist':
            return <PharmacistIcon />;
    }
};

const mapSubIconSrc = (icon?: SubIconType, cardType?: string) => {
    if (cardType) return <CreditCardIcon variant={cardType} isSmallIcon />;

    switch (icon) {
        case 'error':
            return <ErrorCircleIcon />;
        case 'refund':
            return <PlusCircleIcon />;
        case 'positive':
            return <PlusCircleIcon />;
        case 'check':
            return <CheckCircleIcon />;
        default:
            return <CreditCardOutlineIcon />;
    }
};

/**
 * @description
 * The IconComposer allows for rendering either icon or custom content.
 *
 * @property {variant} [IconComposerVariants] - Optional, determines if a custom content will be used instead of an icon.
 * @property {content} [React.ReactElement] - Renders a ReactElement when variant is of type 'content'.
 * @property {iconType} [IconType] - Renders an IconType component by default.
 *
 */

type IconComposerVariants = 'content';

interface IconComposerProps {
    variant?: IconComposerVariants;
    iconType?: IconType;
    content?: React.ReactElement;
    subIconType?: SubIconType;
    orderPaymentCardTypeDesc?: string;
}

const IconComposer = ({ variant, iconType, content, subIconType, orderPaymentCardTypeDesc }: IconComposerProps) => {
    const renderVariant = () => {
        if (variant === 'content') return content;
        return mapIconSrc(iconType as IconType);
    };

    return (
        <div className="icon-composer-icon">
            <div className="icon-composer-icon-type">
                {renderVariant()}
                {(subIconType || orderPaymentCardTypeDesc) && (
                    <div className="icon-composer-icon-type-append">
                        {mapSubIconSrc(subIconType, orderPaymentCardTypeDesc)}
                    </div>
                )}
            </div>
        </div>
    );
};

export default IconComposer;
