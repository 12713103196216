import React, { ReactNode } from 'react';

import './health-benefits-card.scss';

export interface HealthBenefitPerkProps {
    icon: ReactNode;
    title: string;
    subtitle: string;
}

export interface HealthBenefitsCardProps {
    healthBenefitsData: HealthBenefitPerkProps[];
}

const HealthBenefitPerk: React.FC<HealthBenefitPerkProps> = ({ icon, title, subtitle }) => {
    return (
        <article className="benefits-card__perk">
            <div className="benefits-card__perk-thumbnail">
                <div className="benefits-card__perk-thumbnail-notification">{icon}</div>
            </div>
            <div className="benefits-card__perk-content">
                <h3 className="benefits-card__perk-content-title">{title}</h3>
                <p className="benefits-card__perk-content-subtitle">{subtitle}</p>
            </div>
        </article>
    );
};
const HealthBenefitsCard: React.FC<HealthBenefitsCardProps> = ({ healthBenefitsData }) => {
    return (
        <section className="benefits-card">
            {healthBenefitsData.map((benefit, index) => (
                <HealthBenefitPerk key={index} icon={benefit.icon} title={benefit.title} subtitle={benefit.subtitle} />
            ))}
        </section>
    );
};

export default HealthBenefitsCard;
