import React, { CSSProperties } from 'react';

export interface PharmacistIconProps {
    className?: string;
    style?: CSSProperties;
}

const PharmacistIcon = ({ className, style }: PharmacistIconProps) => {
    return (
        <div className={className} style={style}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                    d="M10.9579 9.05882C12.873 9.05882 14.4255 7.47866 14.4255 5.52941C14.4255 3.58017 12.873 2 10.9579 2C9.04274 2 7.49023 3.58017 7.49023 5.52941C7.49023 7.47866 9.04274 9.05882 10.9579 9.05882Z"
                    stroke="#93A9B8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2 21.3382C2 19.0566 2.88797 16.8684 4.46856 15.255C6.04916 13.6416 8.1929 12.7353 10.4282 12.7353C11.0989 12.7353 11.7614 12.8169 12.4029 12.9748V21.3382"
                    stroke="#93A9B8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.35707 16.1177V19.0589M6.91211 17.5882L9.80181 17.5882"
                    stroke="#93A9B8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M21.3586 12.5883H16.2239C15.8694 12.5883 15.582 12.9124 15.582 13.3123V21.276C15.582 21.6759 15.8694 22 16.2239 22H21.3586C21.7131 22 22.0005 21.6759 22.0005 21.276V13.3123C22.0005 12.9124 21.7131 12.5883 21.3586 12.5883Z"
                    stroke="#93A9B8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.1582 19.1639H19.1209"
                    stroke="#93A9B8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

export default PharmacistIcon;
